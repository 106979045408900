const constants = {
  salesContact: '9382824141',
  testingNumbers: ['9898987878', '8080808080'],
  testingOtp: '123123',
  serviceAPI: {
    truckRatesByGroup: 'rates/truck_rates_by_group',
  },
  urls: {
    home: '/',
    login: '/login',
    verify: '/verify',
    register: '/register',
    city: '/register/city',
    indent: '/indent',
    indentFilter: '/indent/filter',
    createIndent: '/indent/create',
    addSource: '/indent/create/source',
    addSourceLocation: '/indent/create/source-location',
    addDestinationLocation: '/indent/create/destination-location',
    addDestination: '/indent/create/destination',
    addTruckType: '/indent/create/trucktype',
    addMaterial: '/indent/create/material',
    addConact: '/indent/create/contact',
    addTon: '/indent/create/ton',
    addDate: '/indent/create/required',
    checkPrice: '/indent/create/check-price',
    trip: '/trips',
    ongoing: '/trips/ongoing',
    podPending: '/trips/pod_pending',
    podReceived: '/trips/pod_received',
    delivered: '/trips/delivered',
    settled: '/trips/settled',
    rates: '/rates',
    chat: '/chat',
    account: '/account',
    profile: '/account/profile',
    users: '/account/users',
    addUser: '/account/users/add',
    help: '/account/help',
    tutorials: '/account/tutorials',
    terms: '/account/terms',
    logout: '/account/logout',
    delete: '/account/delete',
    tripTracking: '/trip',
    indentDetail: '/indent/:indentId',
    indentEdit: '/indent/:indentId/edit',
    tripDetail: '/trips/:tripId',
    tripDetailDocument: '/trips/:tripId/documents',
    tripDetailSummary: '/trips/:tripId/summary',
    tripDetailTracking: '/trips/:tripId/tracking',
    tripAdvance: '/trips/:tripId/advance',
    tripBalance: '/trips/:tripId/balance',
    tripDetailShowMore: '/trips/:tripId/showmore'
  },
  webUrls: {
    youtube: 'https://www.youtube.com/embed/',
    terms: 'https://www.fr8.in/terms.html',
    whatsApp: 'https://api.whatsapp.com/send?text=',
    imgYoutube: (video_id: string) =>
      `https://img.youtube.com/vi/${video_id}/maxresdefault.jpg`,
    gp_loads_url: 'https://play.google.com/store/apps/details?id=in.fr8.partner',
    ios_loads_url: 'https://apps.apple.com/in/app/fr8-loads-full-truck-loads/id6550904007',
    utmOrganic: 'utm_source=google&utm_medium=organic',
    utmBookApp: 'utm_source=bookApp&utm_medium=organic',
  },
  ton: [
    { value: 5 },
    { value: 6 },
    { value: 6.5 },
    { value: 7 },
    { value: 7.5 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12 },
    { value: 13 },
    { value: 14 },
    { value: 15 },
    { value: 16 },
    { value: 17 },
    { value: 18 },
    { value: 19 },
    { value: 20 },
    { value: 21 },
    { value: 22 },
    { value: 23 },
    { value: 24 },
    { value: 25 },
    { value: 26 },
    { value: 27 },
    { value: 28 },
    { value: 29 },
    { value: 30 },
    { value: 31 },
    { value: 32 },
  ],
  mxl_grouped_ids: [25, 26, 36],
  mxl_grouped_string: '36,25,26',
  mxl_group_id: 1,
  initialRoute: {
    sAliasId: null,
    sourceId: 4,
    sourceBranchId: 6,
    sourceName: 'Chennai',
    dAliasId: null,
    destinationId: 253,
    destinationBranchId: 7,
    destinationName: 'Bangalore',
  },
  activeTrip: [2, 3, 4, 5, 6, 9, 10, 11],
  tenTwelveFourteenWhlGroupIds: [5, 6, 7],
  truckGroup: {
    MXL: {
      id: 1,
      primary_group_name: '32FT MXL',
      web_name: '32 Feet Multi Axle Truck',
      ton: 15,
      percentValue: 1,
    },
    SXL: {
      id: 2,
      primary_group_name: '32FT SXL',
      web_name: '32 Feet Single Axle Truck',
      ton: 7,
      percentValue: 1,
    },
    '20FT': {
      id: 3,
      primary_group_name: '20/22/24FT',
      web_name: '20 Feet Container Truck',
      ton: 6,
      percentValue: 1,
    },
    '20FTO': {
      id: 4,
      primary_group_name: '17/19/20/22/24FT',
      web_name: '20 Feet Open Truck',
      ton: 6,
      percentValue: 1,
    },
    '10Whl': {
      id: 5,
      primary_group_name: '10 Whl',
      web_name: '10 Wheeler Open Truck',
      ton: 19,
      percentValue: 0.8,
    },
    '12Whl': {
      id: 6,
      primary_group_name: '12 Whl',
      web_name: '12 Wheeler Open Truck',
      ton: 25,
      percentValue: 0.75,
    },
    '14Whl': {
      id: 7,
      primary_group_name: '14 Whl',
      web_name: '14 Wheeler Open Truck',
      ton: 30,
      percentValue: 0.7,
    },
  },
  SUBSCRIPTION_PLAN_STATUS :{
    COMPLETED: 1
  },
  SUBSCRIPTION_ORIGIN_TYPE :{
    MANUAL: 1,
    AUTO_RENEWAL: 2,
    HIGHER_ORDER: 3,
    COUPON: 4
  },
  COUPON_STATUS :{
    PENDING: 1,
    AVAILABLE: 2,
    ON_YOUR_WAY: 3
  },
  DEFAULT_SOURCING_MANAGER_EMPLOYEE:807,
  EMPLOYEE_ROLE :{
    ADMIN:1
  },
  EMPLOYEE_STATUS:{
    ACTIVE:1
  },
  EMPLOYEE_MARITAL_STATUS:{
    MARRIED:2
  },
  GENDER:{
    MALE:1,
    FEMALE:2
  },
  RELATION:{
    FATHER:1,
    MOTHER:2,
    SPOUSE:5,
    DAUGHTER:4,
    SON:3
  },
  API_TYPE_ID:{
    ICICI:1
  },
  EVENT_NAME: {
    PAGE_VIEW: 'CustomPageView',
    USER_DATA: 'userData',
    START_GTM: 'gtm.js'
  },
  EMPLOYEE_RESIGNATION_STATUS:{
    PENDING:3,
    MANAGER_APPROVED:4,
    REJECTED:2
  },
  EMPLOYEE_FUNCTION:{
    PARTNER_SUPPORT_FUNCTION_ID : 2,
  },
  FUNCTION_GROUP:{
    PARTNER_SUPPORT_FUNCTION_GROUP_ID : 7,
    OPERATIONS_FUNCTION_GROUP_ID : 3
  },
  TRIP_STATUS_ID:{
    CANCELLED: 7
  }
}

export default constants
